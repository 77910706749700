import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../axiosConfig';
import { TailSpin } from 'react-loader-spinner';  // Importa o spinner de carregamento

function ProjectPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalProgress, setTotalProgress] = useState(0);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectResponse = await api.get(`http://localhost:3001/api/projects/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        setProject(projectResponse.data);

        const postsResponse = await api.get(`http://localhost:3001/api/projects/${id}/posts`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        const postsData = postsResponse.data;
        setPosts(postsData);

        const total = postsData.reduce((sum, post) => sum + parseInt(post.progress, 10), 0);
        setTotalProgress(Math.min(total, 100)); // Limita a 100%
      } catch (error) {
        console.error('Error fetching project data:', error);
        navigate('/404');
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [id, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-base">
        <TailSpin
          height="80"
          width="80"
          color="#b07a3a"
          ariaLabel="loading"
        />
        <span className="ml-4 text-white text-lg">Carregando...</span>
      </div>
    );
  }

  if (!project) {
    return <div>Projeto não encontrado.</div>;
  }

  return (
    <div className="project-detail bg-base min-h-screen p-4 flex flex-col">
      <h1 className="text-center text-xl font-bold mb-2 text-white">Linha do Tempo - {project.name}</h1>
      
      {/* Barra de Progresso */}
      <div className="w-4/5 mx-auto mb-4">
        <div className="overflow-hidden h-4 mb-1 text-xs flex rounded bg-gray-300">
          <div
            style={{
              width: `${totalProgress}%`,
              backgroundImage: 'repeating-linear-gradient(45deg, #b07a3a, #b07a3a 10px, #f1e8dd 10px, #f1e8dd 20px)', // Cores ajustadas
              transition: 'width 2s ease-in-out',
            }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
          />
        </div>
        {/* Número de Progresso abaixo da barra */}
        <div className="text-center text-white text-sm font-semibold">
          {totalProgress}%
        </div>
      </div>

      {/* Conteúdo scrollable */}
      <div className="post-timeline flex flex-col items-center space-y-4 overflow-y-auto h-full">
        {posts.map((post) => (
          <div 
            key={post._id} 
            className="post-item w-4/5 p-6 border border-gray-300 rounded-lg shadow-md bg-white"
          >
            <h2 className="text-2xl font-semibold text-black">{post.title}</h2>
            <div 
              className="text-gray-800 mt-2"
              dangerouslySetInnerHTML={{ __html: post.content }} // Renderiza o HTML
            />
            {post.image && <img src={post.image} alt={post.title} className="mt-4 w-full h-auto rounded" />}
            {post.video && <video controls className="mt-4 w-full h-auto rounded"><source src={post.video} /></video>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectPage;
