import React, { useState, useEffect } from 'react';
import { format, parseISO, differenceInMinutes } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ScheduleModal = ({ selectedDay, tasks, onClose, onAddTask, onUpdateTask, onDeleteTask }) => {
  const MySwal = withReactContent(Swal);
  const [visible, setVisible] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [newTask, setNewTask] = useState({
    description: '',
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    setVisible(true);

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const handleClickOutside = (event) => {
    if (event.target.id === 'modal-overlay') {
      handleClose();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTaskClick = (task) => {
    if (task && task._id) {  // Usando _id ao invés de id
      setEditingTask(task);
      setNewTask({
        description: task.description,
        startDate: format(parseISO(task.startDate), 'HH:mm'),
        endDate: format(parseISO(task.endDate), 'HH:mm'),
      });
    } else {
      console.error('ID da tarefa não encontrado');
    }
  };
  
  const handleSaveTask = () => {
    if (newTask.description && newTask.startDate && newTask.endDate) {
      const startDate = new Date(selectedDay);
      const [startHour, startMinute] = newTask.startDate.split(':');
      startDate.setHours(startHour, startMinute);
  
      const endDate = new Date(selectedDay);
      const [endHour, endMinute] = newTask.endDate.split(':');
      endDate.setHours(endHour, endMinute);
  
      // Verificar se a hora de término é maior que a hora de início
      if (endDate <= startDate) {
        MySwal.fire({
          title: 'Erro',
          text: 'A hora de término deve ser maior que a hora de início.',
          icon: 'error',
          confirmButtonColor: '#D2A67D',
        });
        return;
      }
  
      // Verificar se a nova tarefa sobrepõe alguma tarefa existente (exceto a que está sendo editada)
      const isOverlapping = tasks.some(task => {
        if (editingTask && task._id === editingTask._id) return false;
        const taskStart = parseISO(task.startDate);
        const taskEnd = parseISO(task.endDate);
        return (
          (startDate >= taskStart && startDate < taskEnd) || 
          (endDate > taskStart && endDate <= taskEnd) || 
          (startDate <= taskStart && endDate >= taskEnd)
        );
      });
  
      if (isOverlapping) {
        MySwal.fire({
          title: 'Erro',
          text: 'Este horário já está ocupado por outra tarefa.',
          icon: 'error',
          confirmButtonColor: '#D2A67D',
        });
        return;
      }
  
      if (editingTask) {
        onUpdateTask({
          ...editingTask,
          description: newTask.description,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        });
      } else {
        onAddTask({
          description: newTask.description,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        });
      }
  
      setNewTask({ description: '', startDate: '', endDate: '' });
      setEditingTask(null);
      // Atualiza o modal sem fechar
    } else {
      MySwal.fire({
        title: 'Erro',
        text: 'Por favor, preencha todos os campos da tarefa.',
        icon: 'error',
        confirmButtonColor: '#D2A67D',
      });
    }
  };
  

  const handleDeleteTask = () => {
    if (editingTask && editingTask._id) {  // Verifique se o _id está presente
      MySwal.fire({
        title: 'Você tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sim, apagar!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          onDeleteTask(editingTask._id);  // Passe o _id para o método de deletar
          setEditingTask(null);
          setNewTask({ description: '', startDate: '', endDate: '' });
        }
      });
    } else {
      console.error("ID da tarefa não está definido.");
    }
  };
  
  

  const hours = Array.from({ length: 24 }, (_, i) => i);

  const renderTasksForHour = () => {
    return tasks
      .filter(task => {
        const taskStart = parseISO(task.startDate);
        // Filtra as tarefas do dia selecionado
        return taskStart.toDateString() === selectedDay.toDateString();
      })
      .map((task, index) => {
        const taskStart = parseISO(task.startDate);
        const taskEnd = parseISO(task.endDate);
        const taskDuration = differenceInMinutes(taskEnd, taskStart) / 60;
        const taskStartHour = taskStart.getHours();
        const taskStartMinutes = taskStart.getMinutes();

        // Calcula a posição de início e a altura proporcional à duração
        const topPosition = (taskStartHour * 60 + taskStartMinutes) / (24 * 60) * 100; // Posição vertical baseada no início
        const height = taskDuration * 100 / 24; // Altura proporcional à duração da tarefa

        return (
          <div
            key={index}
            className="absolute bg-base text-white p-2 rounded overflow-hidden border border-gray-300 shadow-lg cursor-pointer"
            style={{
              top: `${topPosition}%`,
              height: `${height}%`,
              left: '50px',
              right: '2px',
              zIndex: '3',
            }}
            onClick={() => handleTaskClick(task)}
          >
            <span className="block truncate">{task.description}</span>
            <span className="block text-sm">{`${format(taskStart, 'HH:mm')} - ${format(taskEnd, 'HH:mm')}`}</span>
          </div>
        );
      });
  };

  return (
    <div
      id="modal-overlay"
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${
        visible ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={handleClickOutside}
    >
      <div
        className={`bg-white rounded-lg overflow-hidden w-full max-w-4xl max-h-[90vh] shadow-lg flex flex-col transition-transform duration-300 ${
          visible ? 'scale-100' : 'scale-90'
        }`}
      >
        <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-semibold">{`Tarefas para ${format(selectedDay, 'dd MMMM yyyy', { locale: ptBR })}`}</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-800">&times;</button>
        </div>
        <div className="flex-grow overflow-auto p-4">
          <div className="relative h-full">
            {renderTasksForHour()}
            {hours.map(hour => (
              <div
                key={hour}
                className="border-b border-gray-300 relative h-16 pl-12"
              >
                <div className="absolute left-2 text-gray-500 text-sm">{`${hour}:00`}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="p-4 border-t flex flex-col sm:flex-row justify-end items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="flex-grow">
                <input
                type="text"
                name="description"
                value={newTask.description}
                onChange={handleInputChange}
                placeholder="Descrição da tarefa"
                className="border rounded p-2 w-full"
                />
            </div>
            <input
                type="time"
                name="startDate"
                value={newTask.startDate}
                onChange={handleInputChange}
                className="border rounded p-2"
            />
            <input
                type="time"
                name="endDate"
                value={newTask.endDate}
                onChange={handleInputChange}
                className="border rounded p-2"
            />
            <button
                className="bg-base text-white font-bold py-2 px-4 rounded hover:bg-hover transition duration-200 ease-in-out"
                onClick={handleSaveTask}
                style={{ minWidth: '120px' }}
            >
                {editingTask ? 'Salvar' : 'Nova Tarefa'}
            </button>
            {editingTask && (
              <button
                className="bg-red-600 text-white font-bold py-2 px-4 rounded hover:bg-red-700 transition duration-200 ease-in-out"
                onClick={handleDeleteTask}
              >
                Apagar
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleModal;
