import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const PrivateRoute = () => {
  const { user } = useAuth(); // Obtenha o usuário autenticado do contexto

  // Se não estiver autenticado, redirecione para a página de login
  if (!user) {
    return <Navigate to="/painel" />;
  }

  // Se estiver autenticado, renderize o componente filho
  return <Outlet />;
};

export default PrivateRoute;
