// src/components/Projects.js
import React from 'react';
import ProjectCard from './ProjectCard';

const projects = [
  {
    id: 1,
    title: "Design de Interiores",
    thumbnail: "/images/DesnInt1.jpg",
    images: ["/images/DesnInt1.jpg", "/images/image4.jpg", "/images/image3.jpg"],
    description: "No ramo de Design de Interiores, a AGAH Arquitetura dedica-se a transformar ambientes internos em espaços que refletem a essência e a personalidade de seus ocupantes. Cada projeto é cuidadosamente elaborado para capturar os sonhos e desejos dos nossos clientes, transformando-os em realidade de maneira leve e sensível. Com uma abordagem focada na originalidade, buscamos criar interiores que não apenas encantam visualmente, mas também proporcionam uma sensação de pertencimento e conforto. Seja em uma residência, escritório ou espaço comercial, nossa missão é conectar as pessoas a ambientes que contam histórias, promovem bem-estar e expressam identidade."
  },
  {
    id: 2,
    title: "Arquitetura Residencial",
    thumbnail: "/images/ArqRes1.jpg",
    images: ["/images/ArqRes1.jpg", "/images/ArqRes2.jpg", "/images/ArqRes3.jpg", "/images/ArqRes4.jpg" ],
    description: "Nosso trabalho em Arquitetura Residencial vai além de simplesmente construir casas; nós tiramos do papel os sonhos de quem deseja um lar com alma e personalidade. Na AGAH Arquitetura, acreditamos que cada residência deve ser uma extensão dos desejos e da identidade de seus moradores. Com uma abordagem sensível e um olhar atento aos detalhes, projetamos espaços que encantam e acolhem, criando ambientes onde cada pessoa se sente verdadeiramente em casa. Nossa arquitetura residencial é sobre criar lugares que conectam os moradores a uma nova realidade, oferecendo um refúgio que ressoa com o que há de mais essencial em cada um."
  },
  {
    id: 3,
    title: "Espaços Externos",
    thumbnail: "/images/EspExt1.jpg",
    images: ["/images/EspExt1.jpg", "/images/EspExt2.mp4", "/images/EspExt3.mp4"],
    description: "Em Paisagismo e Espaços Externos, a AGAH Arquitetura se empenha em transformar áreas externas em verdadeiros oásis que dialogam harmoniosamente com o ambiente natural e os desejos dos seus usuários. Nossos projetos são pensados para criar conexões emocionais, convidando as pessoas a desfrutarem de uma arquitetura que é leve, sensível e cheia de vida. Cada espaço externo que projetamos é único, desenhado com originalidade e um profundo respeito pela natureza, refletindo a personalidade de seus ocupantes e proporcionando uma sensação de pertencimento. Queremos que cada jardim, pátio ou área de lazer seja um lugar onde você se sinta encantado e verdadeiramente em casa, em perfeita sintonia com o seu entorno."
  }
];

const Projects = () => {
  return (
    <section id="projetos" className="min-h-screen p-16 bg-gray-100">
      <h2 className="text-3xl mb-8 text-center">Nossos Projetos</h2>
      <div className="flex flex-wrap justify-center">
        {projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
      {/* Botão Ver Mais */}
      <div className="flex justify-center mt-8">
        <a
          href="https://www.instagram.com/agaharquitetura" 
          target="_blank" 
          rel="noopener noreferrer"
          className="bg-base hover:bg-hover text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline transition duration-300"
        >
          Ver Mais
        </a>
      </div>
    </section>
  );
};

export default Projects;
