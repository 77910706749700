// src/components/PainelArmazenamento.js
import React from 'react';
import ListComponent from './ListComponent';

const PainelArmazenamento = () => {
  const armazenamentos = ['Arquivo 1', 'Arquivo 2', 'Arquivo 3', 'Arquivo 4']; // Exemplo de armazenamento

  return (
    <div className="p-8">
      <ListComponent items={armazenamentos} title="Armazenamento" />
    </div>
  );
};

export default PainelArmazenamento;
