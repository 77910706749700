import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PostEditor = ({ onSave, onCancel, initialData }) => {
  const [title, setTitle] = useState(initialData ? initialData.title : '');
  const [content, setContent] = useState(initialData ? initialData.content : '');
  const [uploadedFiles, setUploadedFiles] = useState(initialData ? initialData.files : []);
  const [progress, setProgress] = useState(initialData ? initialData.progress : 0);

  const onDrop = (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map((file) => ({
      id: uuidv4(),
      preview: URL.createObjectURL(file),
      file,
    }));
    setUploadedFiles([...uploadedFiles, ...filesWithPreview]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSave = () => {
    const postData = {
      title,
      content,
      files: uploadedFiles.map((file) => file.file),
      progress,
    };
    onSave(postData);
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Título do Post</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Digite o título do post..."
          className="w-full p-2 border rounded bg-white"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Conteúdo do Post</label>
        <ReactQuill
          value={content}
          onChange={setContent}
          placeholder="Escreva o conteúdo do post..."
          className="w-full p-2 border rounded bg-white"
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ['link', 'image', 'video'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'font',
            'list',
            'bullet',
            'bold',
            'italic',
            'underline',
            'strike',
            'align',
            'color',
            'background',
            'link',
            'image',
            'video',
          ]}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Progresso do Projeto</label>
        <div className="relative w-full h-2 rounded bg-gray-300">
          <div
            className="absolute top-0 left-0 h-full rounded bg-base"
            style={{ width: `${progress}%` }}
          ></div>
          <div
            className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-4 w-4 bg-base rounded-full"
            style={{ left: `${progress}%` }}
          ></div>
          <input
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={(e) => setProgress(e.target.value)}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
        </div>
        <div className="text-center text-gray-700 mt-2">{progress}</div>
      </div>
      <div {...getRootProps()} className="p-4 border-dashed border-2 border-gray-300 rounded mb-4 cursor-pointer">
        <input {...getInputProps()} />
        <p className="text-center text-gray-600">Arraste e solte arquivos aqui, ou clique para selecionar arquivos</p>
      </div>

      {uploadedFiles.length > 0 && (
        <div className="mt-4">
          <h4 className="font-bold mb-2">Arquivos Anexados</h4>
          <ul className="space-y-2">
            {uploadedFiles.map((file) => (
              <li key={file.id} className="flex items-center space-x-4">
                {file.file.type.startsWith('image/') && (
                  <img src={file.preview} alt={file.file.name} className="w-16 h-16 object-cover rounded" />
                )}
                <span>{file.file.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="flex justify-end mt-4">
        <button
          onClick={onCancel}
          className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2 hover:bg-gray-700 transition duration-200 ease-in-out"
        >
          Cancelar
        </button>
        <button
          onClick={handleSave}
          className="bg-base text-white font-bold py-2 px-4 rounded hover:bg-hover transition duration-200 ease-in-out"
        >
          Salvar Post
        </button>
      </div>
    </div>
  );
};

export default PostEditor;
