import React, { useState, useEffect } from 'react';
import ListComponent from './ListComponent';
import api from '../axiosConfig';
import ModalBase from './ModalBase';
import ClientForm from './ClientForm';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const PainelClientes = () => {
  const [clientes, setClientes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await api.get('/clients', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        });
        setClientes(response.data);
      } catch (error) {
        console.error("Erro ao carregar clientes:", error);
        MySwal.fire('Erro!', 'Ocorreu um erro ao carregar os clientes.', 'error');
      }
    };

    fetchClientes();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEdit = (client) => {
    setSelectedClient(client);
    setShowModal(true);
  };

  const handleDelete = async (client) => {
    const result = await MySwal.fire({
      title: 'Tem certeza?',
      text: `Você realmente deseja remover o cliente ${client.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await api.delete(`/clients/${client._id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        });
        setClientes(clientes.filter(c => c._id !== client._id));
        MySwal.fire(
          'Removido!',
          `O cliente ${client.nome} foi removido.`,
          'success'
        );
      } catch (error) {
        if (error.response && error.response.status === 400) {
          MySwal.fire(
            'Erro!',
            error.response.data.message,
            'error'
          );
        } else {
          console.error("Erro ao remover cliente:", error);
          MySwal.fire(
            'Erro!',
            'Ocorreu um erro ao remover o cliente.',
            'error'
          );
        }
      }
    }
  };

  const handleAddClient = async (clientData) => {
    try {
      if (selectedClient) {
        // Se houver um cliente selecionado, estamos editando
        await api.put(`/clients/${selectedClient._id}`, clientData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        });
        setClientes(clientes.map(c => (c._id === selectedClient._id ? { ...clientData, _id: selectedClient._id } : c)));
        MySwal.fire('Editado!', 'O cliente foi editado com sucesso.', 'success');
        setSelectedClient(null);
      } else {
        // Caso contrário, estamos adicionando um novo cliente
        const response = await api.post('/clients', clientData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        });
        setClientes([...clientes, response.data]);
        MySwal.fire('Adicionado!', 'O cliente foi adicionado com sucesso.', 'success');
      }
      setShowModal(false);
    } catch (error) {
      console.error("Erro ao adicionar/editar cliente:", error);
      MySwal.fire('Erro!', 'Ocorreu um erro ao adicionar/editar o cliente.', 'error');
    }
  };

  const filteredClientes = clientes.filter(cliente =>
    cliente.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-8">
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Pesquisar clientes..."
          value={searchTerm}
          onChange={handleSearch}
          className="border rounded p-2 flex-grow mr-4"
        />
        <button
          className="border-2 border-white text-white bg-base font-bold py-2 px-4 rounded hover:bg-white hover:text-base transition duration-200 ease-in-out"
          onClick={() => {
            setSelectedClient(null);
            setShowModal(true);
          }}
        >
          Adicionar Cliente
        </button>
      </div>
      <ListComponent
        items={filteredClientes}
        title="Clientes"
        onEdit={handleEdit}
        onDelete={handleDelete}
        showToggle={false}
      />
      {showModal && (
        <ModalBase title={selectedClient ? "Editar Cliente" : "Adicionar Novo Cliente"} onClose={() => setShowModal(false)}>
          <ClientForm
            onSubmit={handleAddClient}
            onCancel={() => setShowModal(false)}
            initialData={selectedClient}
          />
        </ModalBase>
      )}
    </div>
  );
};

export default PainelClientes;
