import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Hero = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 768, // Mobile view (768px and below)
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 1024, // Tablet view
            settings: {
              slidesToShow: 2,
            }
          }
        ]
      };

  const images = [
    { id: 1, src: '/images/image1.jpg', alt: 'Projeto 1' },
    { id: 2, src: '/images/image2.jpg', alt: 'Projeto 2' },
    { id: 3, src: '/images/image3.jpg', alt: 'Projeto 3' },
    { id: 4, src: '/images/image4.jpg', alt: 'Projeto 4' },
  ];

  return (
    <section id="inicio" className="min-h-[60vh] md:min-h-[80vh] flex flex-col md:flex-row items-center justify-between p-4 md:p-16">
      <div className="w-full md:w-2/3">
        <Slider {...settings}>
          {images.map((image) => (
            <div key={image.id} className="p-4 md:p-8">
              <img src={image.src} alt={image.alt} className="w-full h-[50vh] md:h-full object-cover rounded-lg shadow-lg" />
            </div>
          ))}
        </Slider>
      </div>
      <div className="w-full md:w-1/3 flex flex-col items-center justify-center text-center mt-8 md:mt-0">
        <img src="/images/logo-ajustada-preta.png" alt="Logo AGAH Arquitetura" className="w-3/4 md:w-1/2 mb-4" />
        <h2 className="text-lg md:text-2xl">Arquitetura & Urbanismo</h2>
      </div>
    </section>
  );
};

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                right: "10px", // Ajusta a posição para dentro da tela
                zIndex: 1,
            }}
            onClick={onClick}
        />
    );
};

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                left: "10px", // Ajusta a posição para dentro da tela
                zIndex: 1,
            }}
            onClick={onClick}
        />
    );
};

export default Hero;
