import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../axiosConfig';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const MySwal = withReactContent(Swal);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    MySwal.fire({
      title: 'Enviando...',
      html: `
        <div style="display: flex; justify-content: center; align-items: center; height: 100px;">
          <div class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
        </div>
      `,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      backdrop: `rgba(0,0,0,0.4)`,
      didOpen: () => {
        MySwal.getHtmlContainer().querySelector('.loader').classList.add('spin');
      },
      customClass: {
        popup: 'no-overflow-popup'
      }
    });

    try {
      const response = await api.post('/sendEmail', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      setLoading(false);
      MySwal.close();

      if (response.status === 200) {
        MySwal.fire({
          title: 'Sucesso!',
          text: 'Mensagem enviada com sucesso!',
          icon: 'success',
          confirmButtonColor: '#D2A67D',
          backdrop: `rgba(0,0,0,0.4)`,
        });

        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
      }
    } catch (error) {
      setLoading(false);
      MySwal.close();

      const encodedMessage = encodeURIComponent(formData.message);

      MySwal.fire({
        title: 'Erro!',
        text: 'Houve um problema ao enviar a mensagem. Deseja tentar entrar em contato via WhatsApp?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Sim, entrar em contato',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#D2A67D',
        cancelButtonColor: '#d33',
        backdrop: `rgba(0,0,0,0.4)`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(`https://wa.me/5527999988880?text=${encodedMessage}`, '_blank');
        }
      });
    }
  };

  return (
    <section id="contato" className="min-h-screen p-8 bg-gray-50 flex flex-col items-center justify-center relative">
      <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">Entre em Contato</h2>
      <form className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg" onSubmit={handleSubmit}>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="name">Nome</label>
          <input 
            className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-base focus:border-transparent transition duration-200 ease-in-out" 
            id="name" 
            type="text" 
            placeholder="Seu nome"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="email">Email</label>
          <input 
            className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-base focus:border-transparent transition duration-200 ease-in-out" 
            id="email" 
            type="email" 
            placeholder="Seu email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="phone">Telefone</label>
          <input 
            className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-base focus:border-transparent transition duration-200 ease-in-out" 
            id="phone" 
            type="tel" 
            placeholder="Seu telefone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="message">Mensagem</label>
          <textarea 
            className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-base focus:border-transparent transition duration-200 ease-in-out" 
            id="message" 
            placeholder="Sua mensagem" 
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="flex items-center justify-center">
          <button 
            className="bg-base hover:bg-hover text-white font-bold py-3 px-6 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hover transition duration-200 ease-in-out transform hover:scale-105" 
            type="submit"
            disabled={loading}
          >
            {loading ? 'Enviando...' : 'Enviar'}
          </button>
        </div>
      </form>
    </section>
  );
}

export default Contact;
