import React, { useState } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, getMonth, getYear, addMonths, subMonths, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import ScheduleModal from './ScheduleModal';

const PainelCalendar = ({ tasks, onAddTask, onUpdateTask, onDeleteTask }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const currentMonth = getMonth(currentDate);
  const currentYear = getYear(currentDate);

  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const startDate = startOfWeek(firstDayOfMonth, { weekStartsOn: 0 });
  const endDate = endOfWeek(lastDayOfMonth, { weekStartsOn: 0 });
  const daysOfMonth = eachDayOfInterval({ start: startDate, end: endDate });

  const handlePrevMonth = () => {
    setCurrentDate(prevDate => subMonths(prevDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(prevDate => addMonths(prevDate, 1));
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setShowModal(true);
  };

  const renderTaskIndicators = (day) => {
    return tasks.filter(task =>
      format(parseISO(task.startDate), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')
    ).map((task, index) => (
      <div
        key={index}
        className="task-indicator"
        title={task.description}
        style={{
          backgroundColor: '#D2A67D',
          height: '16px',
          width: 'calc(100% - 4px)',
          position: 'absolute',
          top: `${index * 20 + 25}px`,
          left: '2px',
          right: '2px',
          borderRadius: '2px',
          color: 'white',
          fontSize: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        {task.description}
      </div>
    ));
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg h-full">
      <div className="flex items-stretch h-full">
        <button onClick={handlePrevMonth} className="self-center text-4xl p-2">
          &#x3c; {/* Seta esquerda */}
        </button>
        <div className="flex flex-col flex-grow overflow-hidden">
          <div>
            <div className="mb-2 text-left p-2">
              <h1 className="text-2xl font-bold text-gray-800">
                {format(firstDayOfMonth, 'MMMM', { locale: ptBR }).toUpperCase()} {currentYear}
              </h1>
            </div>
            <div className="grid grid-cols-7 gap-0 text-center border-t border-gray-300">
              {['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'].map(day => (
                <div key={day} className="font-medium text-gray-600 uppercase py-1 border-b border-gray-300">
                  {day}
                </div>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-7 gap-0 text-center overflow-hidden h-full">
            {daysOfMonth.map(day => {
              const isCurrentMonth = getMonth(day) === currentMonth;
              return (
                <div
                  key={day.toString()}
                  className={`relative border border-gray-300 flex flex-col items-start justify-start p-1 cursor-pointer ${
                    isCurrentMonth ? 'bg-white' : 'bg-gray-200'
                  }`}
                  onClick={() => handleDayClick(day)}
                >
                  <div className={`text-sm font-semibold absolute top-1 left-1 ${isCurrentMonth ? 'text-gray-800' : 'text-gray-500'}`}>
                    {format(day, 'd').toUpperCase()}
                  </div>
                  {renderTaskIndicators(day)}
                </div>
              );
            })}
          </div>
        </div>
        <button onClick={handleNextMonth} className="self-center text-4xl p-2">
          &#x3e; {/* Seta direita */}
        </button>
      </div>

      {/* Modal para mostrar tarefas */}
      {showModal && (
        <ScheduleModal
          selectedDay={selectedDay}
          tasks={tasks}
          onClose={() => setShowModal(false)}
          onAddTask={onAddTask}
          onUpdateTask={onUpdateTask}
          onDeleteTask={onDeleteTask}  // Passando a função onDeleteTask
        />
      )}
    </div>
  );
};

export default PainelCalendar;
