import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCogs } from '@fortawesome/free-solid-svg-icons';

const ListComponent = ({
  items,
  title,
  onEdit,
  onDelete,
  onToggle,
  onManage, // Função para gerenciar posts
  primaryKey = 'nome',
  secondaryKey = 'email',
  tertiaryKey = 'tipo',
  showToggle = true,
  showManage = false, // Flag para mostrar o ícone de gerenciamento
  toggleKey = 'ativo',
  showDate = false // Nova flag para decidir entre exibir data ou tipo
}) => {
  
  const handleToggle = (item) => {
    const newStatus = !item[toggleKey];
    onToggle && onToggle({ ...item, [toggleKey]: newStatus });
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg h-full">
      <h3 className="text-xl font-bold mb-4">{title}</h3>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li key={index} className="flex justify-between items-center border-b pb-2">
            <div>
              <div className="font-semibold">{item[primaryKey]}</div>
              <div className="text-sm text-gray-500">{item[secondaryKey]}</div>
              <div className="text-sm text-gray-500">
                {showDate ? `Data: ${item[tertiaryKey]}` : `Tipo: ${item[tertiaryKey]}`}
              </div>
            </div>
            <div className="flex items-center space-x-2">
              {showManage && (
                <button
                  onClick={() => onManage(item)}
                  className="text-green-500 hover:text-green-700"
                  title="Gerenciar"
                >
                  <FontAwesomeIcon icon={faCogs} />
                </button>
              )}
              <button
                onClick={() => onEdit(item)}
                className="text-blue-500 hover:text-blue-700"
                title="Editar"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                onClick={() => onDelete(item)}
                className="text-red-500 hover:text-red-700"
                title="Remover"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              {showToggle && (
                <label className="inline-flex items-center cursor-pointer" title="Ativar/Inativar">
                  <input
                    type="checkbox"
                    checked={item[toggleKey]}
                    onChange={() => handleToggle(item)}
                    className="hidden"
                  />
                  <div className={`w-10 h-5 flex items-center rounded-full p-1 ${item[toggleKey] ? 'bg-base' : 'bg-gray-300'}`}>
                    <div
                      className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform ${item[toggleKey] ? 'translate-x-5' : ''}`}
                    ></div>
                  </div>
                </label>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListComponent;
