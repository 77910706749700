import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faGithub, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-base text-white py-4 flex flex-col md:flex-row items-center justify-between px-6">
      {/* Logo e Copyright à Esquerda */}
      <div className="w-full md:w-1/3 flex items-center justify-center md:justify-start space-x-2 mb-4 md:mb-0">
        <img src="./images/logo-ajustada.png" alt="Logo AGAH Arquitetura" className="w-auto h-5" />
        <p className="text-xs md:text-sm">&copy; 2024 AGAH Arquitetura</p>
      </div>

      {/* Ícones de Redes Sociais Centralizados */}
      <div className="w-full md:w-1/3 flex justify-center space-x-6 mb-4 md:mb-0">
        <a href="https://www.instagram.com/agaharquitetura" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="w-6 h-6 hover:opacity-75 transition-opacity duration-300" />
        </a>
        <a href="https://www.linkedin.com/in/val%C3%A9ria-heringer-6672632a8" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6 hover:opacity-75 transition-opacity duration-300" />
        </a>
        <a href="https://wa.me/5527999988880" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} className="w-6 h-6 hover:opacity-75 transition-opacity duration-300" />
        </a>
      </div>

      {/* Desenvolvedor e GitHub à Direita */}
      <div className="w-full md:w-1/3 flex items-center justify-center md:justify-end space-x-2">
        <p className="text-xs md:text-sm">Desenvolvido por Victor Alonso Heringer</p>
        <a href="https://github.com/VictorAlonsoHeringer" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} className="w-6 h-6 hover:opacity-75 transition-opacity duration-300" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
