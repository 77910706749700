import React from 'react';
import { format, isToday, isTomorrow, parseISO, compareAsc, isAfter } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

const TaskList = ({ tasks }) => {

  // Função para formatar a data e horário
  const formatDateTime = (startDate, endDate) => {
    const parsedStartDate = parseISO(startDate);
    const parsedEndDate = parseISO(endDate);

    let dateLabel = '';

    if (isToday(parsedStartDate)) {
      dateLabel = 'Hoje';
    } else if (isTomorrow(parsedStartDate)) {
      dateLabel = 'Amanhã';
    } else {
      dateLabel = format(parsedStartDate, 'dd MMM', { locale: ptBR });
    }

    const startTime = format(parsedStartDate, 'HH:mm');
    const endTime = format(parsedEndDate, 'HH:mm');

    return `${dateLabel} - ${startTime} às ${endTime}`;
  };

  // Função para filtrar e ordenar as tarefas por data e horário de início
  const today = new Date();
  const filteredAndSortedTasks = tasks
    .filter(task => isAfter(parseISO(task.startDate), today) || isToday(parseISO(task.startDate)))
    .sort((a, b) => compareAsc(parseISO(a.startDate), parseISO(b.startDate)));

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg h-full">
      <h3 className="text-xl font-bold mb-4">Tarefas</h3>
      <ul className="space-y-2">
        {filteredAndSortedTasks.map((task, index) => (
          <li key={index} className="border-b pb-2">
            <span className="font-semibold">{formatDateTime(task.startDate, task.endDate)}:</span> {task.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskList;
