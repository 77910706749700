import React, { useState, useEffect } from 'react';

const ClientForm = ({ onSubmit, onCancel, initialData }) => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [tipo, setTipo] = useState('Física');
  const [cpfCnpj, setCpfCnpj] = useState('');

  useEffect(() => {
    if (initialData) {
      setNome(initialData.nome || '');
      setEmail(initialData.email || '');
      setTelefone(initialData.telefone || '');
      setTipo(initialData.tipo || 'Física');
      setCpfCnpj(initialData.cpfCnpj || '');
    }
  }, [initialData]);

  const handleTipoChange = (e) => {
    setTipo(e.target.value);
    setCpfCnpj(''); // Limpa o campo de CPF/CNPJ ao mudar o tipo de pessoa
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const clientData = {
      _id: initialData?._id || undefined,  // Inclui o _id para edição, se disponível
      nome,
      email,
      telefone,
      tipo,
      cpfCnpj,
    };
    onSubmit(clientData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nome">
          Nome
        </label>
        <input
          type="text"
          id="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          className="border rounded p-2 w-full"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
          Email
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border rounded p-2 w-full"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="telefone">
          Telefone
        </label>
        <input
          type="tel"
          id="telefone"
          value={telefone}
          onChange={(e) => setTelefone(e.target.value)}
          className="border rounded p-2 w-full"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tipo">
          Tipo de Pessoa
        </label>
        <select
          id="tipo"
          value={tipo}
          onChange={handleTipoChange}
          className="border rounded p-2 w-full"
        >
          <option value="Física">Física</option>
          <option value="Jurídica">Jurídica</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cpfCnpj">
          {tipo === 'Física' ? 'CPF' : 'CNPJ'}
        </label>
        <input
          type="text"
          id="cpfCnpj"
          value={cpfCnpj}
          onChange={(e) => setCpfCnpj(e.target.value)}
          className="border rounded p-2 w-full"
          required
          placeholder={tipo === 'Física' ? '000.000.000-00' : '00.000.000/0000-00'}
        />
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2 hover:bg-gray-700 transition duration-200 ease-in-out"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="bg-base text-white font-bold py-2 px-4 rounded hover:bg-hover transition duration-200 ease-in-out"
        >
          {initialData ? 'Atualizar' : 'Salvar'}
        </button>
      </div>
    </form>
  );
};

export default ClientForm;
