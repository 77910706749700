import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Assumindo que você está usando react-router

const Header = () => {
  const [activeSection, setActiveSection] = useState('inicio');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        threshold: 0.5, // Quando 50% da seção estiver visível
      }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <header className="bg-base p-4 flex justify-between items-center fixed w-full top-0 z-50 shadow-md transition-all duration-300 ease-in-out">
      <div className="text-white text-2xl md:text-3xl font-bold cursor-pointer flex-grow">
        <img src='./images/logo-ajustada.png' alt='logo' className="w-auto h-10 md:h-12" />
      </div>
      <nav className="hidden md:block">
        <ul className="flex space-x-8 text-lg">
          <li>
            <a
              href="#inicio"
              className={`relative text-white hover:text-gray-300 transition duration-300 ease-in-out ${activeSection === 'inicio' ? 'active' : ''}`}
            >
              Início
              {activeSection === 'inicio' && <span className="absolute left-0 right-0 -bottom-2 h-1 bg-white"></span>}
            </a>
          </li>
          <li>
            <a
              href="#projetos"
              className={`relative text-white hover:text-gray-300 transition duration-300 ease-in-out ${activeSection === 'projetos' ? 'active' : ''}`}
            >
              Projetos
              {activeSection === 'projetos' && <span className="absolute left-0 right-0 -bottom-2 h-1 bg-white"></span>}
            </a>
          </li>
          <li>
            <a
              href="#sobre-nos"
              className={`relative text-white hover:text-gray-300 transition duration-300 ease-in-out ${activeSection === 'sobre-nos' ? 'active' : ''}`}
            >
              Sobre Nós
              {activeSection === 'sobre-nos' && <span className="absolute left-0 right-0 -bottom-2 h-1 bg-white"></span>}
            </a>
          </li>
          <li>
            <a
              href="#contato"
              className={`relative text-white hover:text-gray-300 transition duration-300 ease-in-out ${activeSection === 'contato' ? 'active' : ''}`}
            >
              Contato
              {activeSection === 'contato' && <span className="absolute left-0 right-0 -bottom-2 h-1 bg-white"></span>}
            </a>
          </li>
          {/* Botão para o Painel Administrativo (exibido apenas no desktop) */}
          <li className="hidden lg:inline-block">
            <Link
              to="/painel"
              className="text-white px-4 py-2 rounded border-2 border-white hover:bg-white hover:text-base transition duration-300 ease-in-out"
            >
              Painel
            </Link>
          </li>
        </ul>
      </nav>
      <div className="md:hidden">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white focus:outline-none"
        >
          <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      {isMenuOpen && (
        <div className="absolute top-16 right-0 w-full bg-base text-white flex flex-col items-center shadow-md z-40 md:hidden">
          <a
            href="#inicio"
            className="py-2 w-full text-center hover:bg-hover transition duration-300 ease-in-out"
            onClick={() => setIsMenuOpen(false)}
          >
            Início
          </a>
          <a
            href="#projetos"
            className="py-2 w-full text-center hover:bg-hover transition duration-300 ease-in-out"
            onClick={() => setIsMenuOpen(false)}
          >
            Projetos
          </a>
          <a
            href="#sobre-nos"
            className="py-2 w-full text-center hover:bg-hover transition duration-300 ease-in-out"
            onClick={() => setIsMenuOpen(false)}
          >
            Sobre Nós
          </a>
          <a
            href="#contato"
            className="py-2 w-full text-center hover:bg-hover transition duration-300 ease-in-out"
            onClick={() => setIsMenuOpen(false)}
          >
            Contato
          </a>
        </div>
      )}
    </header>
  );
};

export default Header;
