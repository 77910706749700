import React, { useState, useEffect } from 'react';

const UserForm = ({ onSubmit, onCancel, onResetPassword, user }) => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [tipo, setTipo] = useState('padrão');

  useEffect(() => {
    if (user) {
      setNome(user.nome);
      setEmail(user.email);
      setTipo(user.tipo);
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ nome, email, tipo });
  };

  const handleResetPassword = () => {
    if (onResetPassword && user) {
      onResetPassword(user._id);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nome">
          Nome
        </label>
        <input
          type="text"
          id="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          className="border rounded p-2 w-full"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
          Email
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border rounded p-2 w-full"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tipo">
          Tipo de Usuário
        </label>
        <select
          id="tipo"
          value={tipo}
          onChange={(e) => setTipo(e.target.value)}
          className="border rounded p-2 w-full"
          required
        >
          <option value="padrão">Padrão</option>
          <option value="administrador">Administrador</option>
        </select>
      </div>
      <div className="flex justify-between items-center">
        {user && (
          <button
            type="button"
            onClick={handleResetPassword}
            className="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700 transition duration-200 ease-in-out"
          >
            Resetar Senha
          </button>
        )}
        <div className="flex justify-end ml-auto">
          <button
            type="button"
            onClick={onCancel}
            className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2 hover:bg-gray-700 transition duration-200 ease-in-out"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="bg-base text-white font-bold py-2 px-4 rounded hover:bg-hover transition duration-200 ease-in-out"
          >
            {user ? "Salvar Alterações" : "Adicionar Usuário"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
