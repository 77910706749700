import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../axiosConfig';
import { useAuth } from '../context/AuthProvider';

const Painel = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
  
    try {
      const response = await api.post('/authenticate', {
        email,
        password,
      });
  
      if (response.status === 200) {
        const token = response.data.token;  // Assumindo que o token é retornado como parte dos dados do usuário
        localStorage.setItem('authToken', token);  // Armazena o token no localStorage
        setIsLoggingIn(true);  // Inicia a animação
        setTimeout(() => {
          login(response.data);
          navigate('/gerenciamento');
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setErrorMessage('Usuário inativo. Contate o administrador.');
      } else {
        setErrorMessage('Login falhou. Verifique suas credenciais.');
      }
      setIsLoggingIn(false);
    }
  };

  return (
    <div className={`relative flex h-screen w-screen overflow-hidden ${isLoggingIn ? 'bg-base' : ''}`}>
      <div 
        className={`w-full md:w-1/3 p-8 flex flex-col justify-center transition-transform duration-[1500ms] transform ${isLoggingIn ? '-translate-x-full' : 'translate-x-0'}`} 
        style={{ backgroundColor: '#f1e8dd' }}
      >
        <h2 className="text-4xl font-bold mb-8">Login</h2>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Senha</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button className="bg-base text-white font-bold py-2 px-4 rounded hover:bg-hover transition duration-200 ease-in-out" type="submit">
              Entrar
            </button>
          </div>
        </form>
      </div>
      <div className={`hidden md:flex md:w-2/3 items-center justify-center bg-base relative transition-transform duration-[1500ms] transform ${isLoggingIn ? 'translate-x-full' : 'translate-x-0'}`}>
        <img 
          src="./images/logo-ajustada.png" 
          alt="Logo" 
          className={`w-2/3 transition-transform duration-[1500ms] transform ${isLoggingIn ? 'translate-x-full scale-125' : 'translate-x-0 scale-100'}`} 
        />
      </div>
      <footer className="fixed bottom-1 right-1 text-xs text-white p-1">
        &copy; 2024 AGAH Arquitetura. Todos os direitos reservados.
      </footer>
    </div>
  );
};

export default Painel;
