import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Projects from './components/Projects';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Painel from './components/Painel';
import Gerenciamento from './components/Gerenciamento';
import ProjectPage from './components/ProjectPage';
import NotFoundPage from './components/NotFoundPage';
import { AuthProvider } from './context/AuthProvider';
import PrivateRoute from './context/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App h-screen flex flex-col">
          <Routes>
            <Route path="/painel" element={<Painel />} />

            {/* Rotas protegidas */}
            <Route element={<PrivateRoute />}>
              <Route path="/gerenciamento" element={<Gerenciamento />} />
            </Route>

            {/* Rota pública */}
            <Route path="/" element={
              <>
                <Header />
                <div className="flex-1 overflow-y-auto mt-16" style={{ scrollBehavior: 'smooth', backgroundColor: 'transparent' }}>
                  <Hero />
                  <Projects />
                  <AboutUs />
                  <Contact />
                  <Footer />
                </div>
              </>
            } />

            {/* Rota para visualização do projeto */}
            <Route path="/projeto/:id" element={<ProjectPage />} />

            {/* Rota para a página 404 */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
