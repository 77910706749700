import React, { useEffect, useState } from 'react';

const ModalBase = ({ title, children, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true); // Inicia a animação de abertura

    // Fecha o modal ao pressionar a tecla Esc
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const handleClose = () => {
    setVisible(false); // Inicia a animação de fechamento
    setTimeout(() => {
      onClose(); // Fecha o modal após a animação
    }, 300); // Tempo correspondente à duração da animação
  };

  const handleClickOutside = (event) => {
    if (event.target.id === 'modal-overlay') {
      handleClose();
    }
  };

  return (
    <div
      id="modal-overlay"
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${
        visible ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={handleClickOutside}
    >
      <div
        className={`bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 max-h-[90vh] p-4 relative transform transition-transform duration-300 ${
          visible ? 'scale-100' : 'scale-90'
        } overflow-y-auto`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">{title}</h2>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
            &times;
          </button>
        </div>
        <div className="overflow-y-auto max-h-[70vh]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalBase;
