import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Inicio from './Inicio';
import Projetos from './PainelProjetos';
import Clientes from './PainelClientes';
import Usuarios from './PainelUsuarios';
import Armazenamento from './PainelArmazenamento';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../axiosConfig';

const MySwal = withReactContent(Swal);

const Gerenciamento = () => {
  const [activePage, setActivePage] = useState('inicio');

  useEffect(() => {
    const checkPassword = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user.user._id;  // Obtenha o ID do usuário do localStorage
        const token = user.token;

        const response = await api.get(`/users/password-status/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true,
        });

        if (response.data.isDefaultPassword) {
          MySwal.fire({
            title: 'Redefina sua senha',
            text: 'Sua senha está definida como padrão. Por favor, altere sua senha.',
            icon: 'warning',
            input: 'password',
            inputPlaceholder: 'Digite sua nova senha',
            inputAttributes: {
              minlength: 6,
              required: true,
            },
            showCancelButton: false,
            confirmButtonText: 'Alterar Senha',
            allowOutsideClick: false,
            preConfirm: (newPassword) => {
              if (!newPassword) {
                MySwal.showValidationMessage('A senha não pode estar vazia.');
              } else if (newPassword === '123@mudar') {
                MySwal.showValidationMessage('Por favor, escolha uma senha diferente da padrão.');
              } else {
                return api.put(`/users/change-password/${userId}`, 
                  { newPassword },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`
                    },
                    withCredentials: true,
                  }
                );
              }
            }
          }).then((result) => {
            if (result.isConfirmed) {
              MySwal.fire('Senha Alterada!', 'Sua senha foi alterada com sucesso.', 'success');
            }
          });
        }
      } catch (error) {
        console.error('Erro ao verificar a senha do usuário:', error);
      }
    };

    checkPassword();
  }, []);

  const renderContent = () => {
    switch (activePage) {
      case 'projetos':
        return <Projetos />;
      case 'clientes':
        return <Clientes />;
      case 'usuarios':
        return <Usuarios />;
      case 'armazenamento':
        return <Armazenamento />;
      default:
        return <Inicio />;
    }
  };

  return (
    <div className="flex h-screen w-screen bg-base">
      <Sidebar setActivePage={setActivePage} />
      <div className="flex-1">
        {renderContent()}
      </div>
      <footer className="fixed bottom-1 right-1 text-xs text-white p-1">
        &copy; 2024 AGAH Arquitetura. Todos os direitos reservados.
      </footer>
    </div>
  );
};

export default Gerenciamento;
