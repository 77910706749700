import React, { useState, useEffect } from 'react';

const ProjectForm = ({ onSubmit, onCancel, project, existingClients, onAddClient }) => {
  const [name, setName] = useState('');
  const [client, setClient] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [isNewClient, setIsNewClient] = useState(false);
  const [newClientName, setNewClientName] = useState('');
  const [newClientEmail, setNewClientEmail] = useState('');
  const [newClientPhone, setNewClientPhone] = useState('');
  const [newClientType, setNewClientType] = useState('Física');
  const [newClientCpfCnpj, setNewClientCpfCnpj] = useState('');

  useEffect(() => {
    if (project) {
      setName(project.name);
      setClient(project.client);
      setCreatedAt(project.createdAt);
    }
  }, [project]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isNewClient) {
      const newClient = {
        nome: newClientName,
        email: newClientEmail,
        telefone: newClientPhone,
        tipo: newClientType,
        cpfCnpj: newClientCpfCnpj,
        ativo: true
      };

      const createdClient = await onAddClient(newClient);
      if (createdClient && createdClient._id) {
        onSubmit({ name, client: createdClient._id, createdAt });
      } else {
        console.error("Erro ao criar cliente: ID do cliente não encontrado.");
      }
    } else {
      onSubmit({ name, client, createdAt });
    }
  };

  const handleClientChange = (e) => {
    if (e.target.value === 'new') {
      setIsNewClient(true);
      setClient('');
    } else {
      setIsNewClient(false);
      setClient(e.target.value);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
          Nome do Projeto
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border rounded p-2 w-full"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="client">
          Cliente
        </label>
        <select
          id="client"
          value={isNewClient ? 'new' : client}
          onChange={handleClientChange}
          className="border rounded p-2 w-full"
          required
        >
          <option value="">Selecione um cliente...</option>
          <option value="new">+ Novo Cliente</option>
          {existingClients.map((client) => (
            <option key={client._id} value={client._id}>
              {client.nome}
            </option>
          ))}
        </select>
      </div>

      {isNewClient && (
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newClientName">
              Nome do Cliente
            </label>
            <input
              type="text"
              id="newClientName"
              value={newClientName}
              onChange={(e) => setNewClientName(e.target.value)}
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newClientEmail">
              Email
            </label>
            <input
              type="email"
              id="newClientEmail"
              value={newClientEmail}
              onChange={(e) => setNewClientEmail(e.target.value)}
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newClientPhone">
              Telefone
            </label>
            <input
              type="tel"
              id="newClientPhone"
              value={newClientPhone}
              onChange={(e) => setNewClientPhone(e.target.value)}
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newClientType">
              Tipo de Pessoa
            </label>
            <select
              id="newClientType"
              value={newClientType}
              onChange={(e) => setNewClientType(e.target.value)}
              className="border rounded p-2 w-full"
              required
            >
              <option value="Física">Física</option>
              <option value="Jurídica">Jurídica</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newClientCpfCnpj">
              {newClientType === 'Física' ? 'CPF' : 'CNPJ'}
            </label>
            <input
              type="text"
              id="newClientCpfCnpj"
              value={newClientCpfCnpj}
              onChange={(e) => setNewClientCpfCnpj(e.target.value)}
              className="border rounded p-2 w-full"
              required
              placeholder={newClientType === 'Física' ? '000.000.000-00' : '00.000.000/0000-00'}
            />
          </div>
        </div>
      )}

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="createdAt">
          Data de Criação
        </label>
        <input
          type="date"
          id="createdAt"
          value={createdAt}
          onChange={(e) => setCreatedAt(e.target.value)}
          className="border rounded p-2 w-full"
          required
        />
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2 hover:bg-gray-700 transition duration-200 ease-in-out"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="bg-base text-white font-bold py-2 px-4 rounded hover:bg-hover transition duration-200 ease-in-out"
        >
          {project ? 'Salvar Alterações' : 'Adicionar Projeto'}
        </button>
      </div>
    </form>
  );
};

export default ProjectForm;
