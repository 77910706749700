// src/components/ProjectCard.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProjectCard = ({ project }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const toggleModal = () => {
    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 300);
    } else {
      setIsOpen(true);
    }
  };

  // Adiciona um listener para fechar o modal ao pressionar Esc
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        toggleModal();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen]);

  // Configurações do carrossel com setas customizadas
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // Função para verificar se é uma imagem ou vídeo
  const isVideo = (src) => {
    return src.endsWith('.mp4') || src.endsWith('.webm') || src.endsWith('.ogg');
  };

  return (
    <div className="w-full md:w-1/2 lg:w-1/3 p-4">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer" onClick={toggleModal}>
        <img src={project.thumbnail} alt={project.title} className="w-full h-[40vh] md:h-[60vh] object-cover" />
        <div className="p-4">
          <h3 className="text-xl font-bold mb-2">{project.title}</h3>
        </div>
      </div>

      {isOpen && (
        <>
          {/* Overlay separado com clique para fechar */}
          <div 
            className={`fixed inset-0 z-40 bg-black bg-opacity-75 ${isClosing ? 'fade-out' : 'fade-in'}`}
            onClick={toggleModal}
          ></div>
          
          {/* Popup modal com layout de estilo Instagram */}
          <div className={`fixed inset-0 z-50 flex items-center justify-center ${isClosing ? 'zoom-out' : 'zoom-in'}`}>
            <div className="relative p-4 md:p-8 bg-white w-full h-full md:h-[72vh] m-auto flex flex-col md:flex-row rounded-none md:rounded-lg overflow-hidden">
              <span className="absolute top-2 right-2 md:top-4 md:right-4 p-2 md:p-4 cursor-pointer z-50 bg-white bg-opacity-75 rounded-full" onClick={toggleModal}>✕</span>

              {/* Contêiner para centralizar o conteúdo */}
              <div className="w-full md:w-1/2 p-2 relative flex justify-center items-center">
                <Slider {...settings} className="w-full">
                  {project.images.map((media, index) => (
                    <div key={index} className="flex justify-center items-center h-full">
                      {isVideo(media) ? (
                        <video controls muted autoplay className="w-full h-full max-h-[40vh] md:max-h-[70vh] object-contain rounded-none md:rounded-lg">
                          <source src={media} type="video/mp4" />
                          Seu navegador não suporta o elemento de vídeo.
                        </video>
                      ) : (
                        <img src={media} alt={`${project.title} ${index + 1}`} className="w-full h-full max-h-[40vh] md:max-h-[70vh] object-contain rounded-none md:rounded-lg" />
                      )}
                    </div>
                  ))}
                </Slider>
              </div>

              {/* Descrição à direita com scroll */}
              <div className="w-full md:w-1/2 p-2 flex flex-col justify-between overflow-y-auto max-h-[70vh]">
                <h3 className="text-2xl font-bold mb-4">{project.title}</h3>
                <p className="text-gray-700 mb-4 text-justify flex-grow">{project.description}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

// Setas Customizadas
const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer bg-black bg-opacity-50 text-white p-2 rounded-full"
      onClick={onClick}
    >
      ❯
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer bg-black bg-opacity-50 text-white p-2 rounded-full"
      onClick={onClick}
    >
      ❮
    </div>
  );
};

export default ProjectCard;
