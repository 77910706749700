// src/components/AboutUs.js
import React from 'react';

const AboutUs = () => {
  return (
    <section id="sobre-nos" className="min-h-screen p-16 flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 p-4">
        <h2 className="text-3xl font-bold mb-4">Sobre Nós</h2>
        <p className="text-gray-700 mb-4">
          Na <strong>AGAH Arquitetura</strong>, acreditamos que a arquitetura vai muito além da criação de espaços. Nossa missão é <strong>conectar pessoas a uma arquitetura leve, sensível e com essência</strong>, traduzindo sonhos em realidade com originalidade e personalidade. Cada projeto é uma oportunidade única de tirar do papel os desejos de nossos clientes, transformando-os em ambientes que refletem sua identidade e aspirações.
        </p>
        <p className="text-gray-700 mb-4">
          Somos uma equipe de profissionais apaixonados pela arte de projetar, e cada um de nós compartilha o compromisso de entregar excelência em tudo o que fazemos. Nosso trabalho é pautado pela criatividade, inovação e um profundo respeito pelas individualidades de cada cliente. Acreditamos que a arquitetura deve encantar, acolher e, acima de tudo, <strong>fazer com que as pessoas se sintam pertencentes a um novo lugar</strong>—um lugar que ressoa com suas vidas e valores.
        </p>
        <p className="text-gray-700 mb-4">
          Estamos aqui para te ouvir, entender suas necessidades e, juntos, construir algo que seja verdadeiramente seu. Na AGAH Arquitetura, <strong>cada projeto é uma jornada de transformação</strong>, onde o resultado final é um espaço que não só atende às expectativas, mas também surpreende e inspira.
        </p>
        <p className="text-gray-700">
          Venha sonhar conosco. Vamos criar juntos o espaço que você sempre desejou, com a leveza e a sensibilidade que só a AGAH Arquitetura pode oferecer.
        </p>
      </div>
      <div className="md:w-1/2 p-4 flex justify-center">
        <img 
          src="/images/aboutMe2.jpg" 
          alt="Sobre Nós" 
          className="w-[60vh] h-[60vh] rounded-full object-cover shadow-lg"
        />
      </div>
    </section>
  );
}

export default AboutUs;
