// src/components/NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-base p-8">
      <h1 className="text-6xl font-bold text-white mb-4">404</h1>
      <p className="text-xl text-white mb-8">Oops! A página que você está procurando não foi encontrada.</p>
      <Link
        to="/"
        className="bg-white text-base font-semibold py-2 px-4 rounded shadow hover:bg-gray-200 transition duration-200"
      >
        Voltar para a página inicial
      </Link>
    </div>
  );
}

export default NotFoundPage;
