import React, { useState, useEffect } from 'react';
import api from '../axiosConfig';
import ListComponent from './ListComponent';
import ModalBase from './ModalBase';
import PostEditor from './PostEditor';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const PostManagement = ({ project, onBack }) => {
  const [posts, setPosts] = useState([]);
  const [showPostEditor, setShowPostEditor] = useState(false);
  const [editPost, setEditPost] = useState(null);
  const [totalProgress, setTotalProgress] = useState(0);

  useEffect(() => {
    fetchPosts();
  }, [project]);

  const fetchPosts = async () => {
    try {
      const response = await api.get(`/projects/${project._id}/posts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        },
        withCredentials: true,
      });
      const postsData = response.data;
      setPosts(postsData);
      calculateTotalProgress(postsData);
    } catch (error) {
      console.error('Erro ao carregar posts:', error);
    }
  };

  const calculateTotalProgress = (posts) => {
    const progress = posts.reduce((total, post) => total + parseInt(post.progress, 10), 0);
    setTotalProgress(Math.min(progress, 100)); // Limitar a 100%
  };

  const handleAddPost = () => {
    setEditPost(null);
    setShowPostEditor(true);
  };

  const handleEditPost = (post) => {
    setEditPost(post);
    setShowPostEditor(true);
  };

  const handleDeletePost = async (post) => {
    try {
      const result = await Swal.fire({
        title: 'Tem certeza?',
        text: `Você realmente deseja remover o post "${post.title}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        await api.delete(`/posts/${post._id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        });
        const updatedPosts = posts.filter(p => p._id !== post._id);
        setPosts(updatedPosts);
        calculateTotalProgress(updatedPosts);
        Swal.fire('Removido!', 'O post foi removido com sucesso.', 'success');
      }
    } catch (error) {
      console.error('Erro ao deletar post:', error);
      Swal.fire('Erro', 'Ocorreu um erro ao deletar o post.', 'error');
    }
  };

  const handleSavePost = async (postData) => {
    try {
      if (editPost) {
        await api.put(`/posts/${editPost._id}`, postData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        });
        const updatedPosts = posts.map(post => (post._id === editPost._id ? { ...post, ...postData } : post));
        setPosts(updatedPosts);
        calculateTotalProgress(updatedPosts);
        Swal.fire('Editado!', 'O post foi editado com sucesso.', 'success');
      } else {
        const response = await api.post(`/projects/${project._id}/posts`, postData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        });
        const updatedPosts = [...posts, response.data];
        setPosts(updatedPosts);
        calculateTotalProgress(updatedPosts);
        Swal.fire('Adicionado!', 'O post foi adicionado com sucesso.', 'success');
      }
      setShowPostEditor(false);
    } catch (error) {
      console.error('Erro ao salvar post:', error);
      Swal.fire('Erro', 'Ocorreu um erro ao salvar o post.', 'error');
    }
  };

  const handleCopyLink = () => {
    const link = `https://agah.arq.br/projeto/${project._id}`;
    navigator.clipboard.writeText(link).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Link copiado!',
        text: 'O link foi copiado para a área de transferência.',
        timer: 1500,
        showConfirmButton: false
      });
    }).catch(err => {
      console.error('Erro ao copiar o link:', err);
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Ocorreu um erro ao copiar o link.',
      });
    });
  };

  return (
    <div className="bg-base">
      <div className="flex justify-between items-center mb-8">
        <button
          onClick={onBack}
          className="border-2 border-base text-base bg-white font-bold py-2 px-4 rounded hover:bg-base hover:text-white transition duration-200 ease-in-out"
        >
          Voltar para Projetos
        </button>
        
        <div className="flex-grow mx-8 mb-2">
          <div className="relative">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block text-white">{`${totalProgress}%`}</span>
              </div>
            </div>
            <div className="overflow-hidden h-4 text-xs flex rounded bg-gray-200">
              <div
                style={{ 
                  width: `${totalProgress}%`,
                  background: 'repeating-linear-gradient(45deg, #4caf50, #4caf50 10px, #8bc34a 10px, #8bc34a 20px)'
                }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
              ></div>
            </div>
          </div>
        </div>

        <button
          onClick={handleAddPost}
          className="border-2 border-white text-white bg-base font-bold py-2 px-4 rounded hover:bg-white hover:text-base transition duration-200 ease-in-out"
        >
          Adicionar Post
        </button>
      </div>

      <ListComponent
        items={posts.map(post => ({
          ...post,
          createdAt: post.createdAt ? format(new Date(post.createdAt), 'dd/MM/yyyy HH:mm') : 'Data Inválida',
          progress: post.progress ? `${post.progress}%` : '0%',
        }))}
        title={(
          <div className="flex items-center">
            <span>Posts do Projeto {project.name}</span>
            <FontAwesomeIcon 
              icon={faLink} 
              className="ml-2 text-gray-500 cursor-pointer hover:text-gray-700" 
              onClick={handleCopyLink} 
              title="Copiar link do projeto"
            />
          </div>
        )}
        primaryKey="title"
        secondaryKey="progress"
        tertiaryKey="createdAt"
        onEdit={handleEditPost}
        onDelete={handleDeletePost}
        showToggle={false}
        showDate={true} // Adiciona a flag para exibir "Data" no lugar de "Tipo"
      />

      {showPostEditor && (
        <ModalBase title={editPost ? "Editar Post" : "Adicionar Novo Post"} onClose={() => setShowPostEditor(false)}>
          <PostEditor
            onSave={handleSavePost}
            onCancel={() => setShowPostEditor(false)}
            initialData={editPost}
            project={project}
          />
        </ModalBase>
      )}
    </div>
  );
};

export default PostManagement;
